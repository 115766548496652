$('button#calculate-stamp-duty').on('click', function () {
    processStampDutyCalculation();
});

$('div.stamp-duty-calculator-wrapper ul.nav a').on('click', function () {
    let target = $(this).data('target');

    let singleLi = $('li.li-single');
    let singleTab = $('div#single');
    let additionalLi = $('li.li-additional');
    let additionalTab = $('div#additional');

    if (target === 'single-property-tab') {
        additionalLi.removeClass('active');
        singleLi.addClass('active');

        additionalTab.removeClass('active show');
        singleTab.addClass('active show');
    } else {
        singleLi.removeClass('active');
        additionalLi.addClass('active');

        singleTab.removeClass('active show');
        additionalTab.addClass('active show');
    }
});

// STAMP DUTY

function processStampDutyCalculation() {

    let price = $('div.stamp-duty-calculator-wrapper input[name="price"]').val();
    let mode = $('div.stamp-duty-calculator-wrapper li.active a').data('target');

    mode = mode === 'single-property-tab' ? 'single' : 'additional';

    if (mode === 'single') {
        processSingleStampDutyCalculation(parseInt(price));
    } else {
        processAdditionalStampDutyCalculation(parseInt(price));
    }

}

function processSingleStampDutyCalculation(price) {
    let stampDuty, effectiveRate, effectiveRateLong;
    let stampDutyBandA = 0, stampDutyBandB = 0, stampDutyBandC = 0, stampDutyBandD = 0, stampDutyBandE = 0;
    let taxSumBandA = 0, taxSumBandB = 0, taxSumBandC = 0, taxSumBandD = 0, taxSumBandE = 0;

    let bandA = $('div#single tr.band-a');
    let bandB = $('div#single tr.band-b');
    let bandC = $('div#single tr.band-c');
    let bandD = $('div#single tr.band-d');
    let bandE = $('div#single tr.band-e');

    if (price < 125000.01) {
        stampDuty = 0;
    } else if (price >= 125000.01 && price <= 50000.00) {
        stampDuty = (price - 125000) * 0.02;
    } else if (price >= 250000.01 && price <= 925000.00) {
        stampDuty = ((price - 250000) * 0.05) + 2500;
    } else if (price >= 925000 && price <= 1500000.00) {
        stampDuty = ((price - 925000) * 0.1) + 36250;
    } else if (price >= 1500000.01) {
        stampDuty = ((price - 1500000) * 0.12) + 93750;
    }

    effectiveRateLong = (stampDuty / price) * 100;
    effectiveRate = Math.round(effectiveRateLong * 100) / 100.000;

    if (price < 125000.01) {
        stampDutyBandA = Math.floor(0);
    } else if (price >= 125000.01 && price <= 250000.00) {
        stampDutyBandB = Math.floor(((price - 125000) * 0.02));
    } else if (price >= 250000.01 && price <= 925000.00) {
        stampDutyBandB = Math.floor(2500);
        stampDutyBandC = Math.floor(((price - 250000) * 0.05));
    } else if (price >= 925000.01 && price <= 1500000.00) {
        stampDutyBandB = Math.floor(2500);
        stampDutyBandC = Math.floor(33750);
        stampDutyBandD = Math.floor(((price - 925000) * 0.1));
    } else if (price >= 1500000.01) {
        stampDutyBandB = Math.floor(2500);
        stampDutyBandC = Math.floor(33750);
        stampDutyBandD = Math.floor(57500);
        stampDutyBandE = Math.floor(((price - 1500000) * 0.12));
    }

    if (price < 125000.01) {
        taxSumBandA = Math.floor(0);
    } else if (price >= 125000.01 && price <= 250000.00) {
        taxSumBandB = Math.floor((price - 125000));
    } else if (price >= 250000.01 && price <= 925000.00) {
        taxSumBandB = Math.floor(125000);
        taxSumBandC = Math.floor((price - 250000));
    } else if (price >= 925000.01 && price <= 1500000.00) {
        taxSumBandB = Math.floor(125000);
        taxSumBandC = Math.floor(675000);
        taxSumBandD = (price - 925000);
    } else if (price >= 1500000.01) {
        taxSumBandB = Math.floor(125000);
        taxSumBandC = Math.floor(675000);
        taxSumBandD = Math.floor(575000);
        taxSumBandE = Math.floor((price - 1500000));
    }

    $('td.taxable-sum', bandA).text('£' + taxSumBandA.toFixed(2).replace('.00', ''));
    $('td.tax', bandA).text('£' + stampDutyBandA.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandB).text('£' + taxSumBandB.toFixed(2).replace('.00', ''));
    $('td.tax', bandB).text('£' + stampDutyBandB.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandC).text('£' + taxSumBandC.toFixed(2).replace('.00', ''));
    $('td.tax', bandC).text('£' + stampDutyBandC.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandD).text('£' + taxSumBandD.toFixed(2).replace('.00', ''));
    $('td.tax', bandD).text('£' + stampDutyBandD.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandE).text('£' + taxSumBandE.toFixed(2).replace('.00', ''));
    $('td.tax', bandE).text('£' + stampDutyBandE.toFixed(2).replace('.00', ''));

    $('div#single td.effective-rate').text(effectiveRate.toFixed(2).replace('.00', '') + '%');
    $('div.stamp-duty-total span.estimated-result').text('£' + stampDuty.toFixed(2).replace('.00', ''));
    $('div.stamp-duty-total').removeClass('d-none');
}

/**
 * @param price
 */
function processAdditionalStampDutyCalculation(price) {
    let stampDuty, effectiveRate, effectiveRateLong;
    let stampDutyBandA = 0, stampDutyBandB = 0, stampDutyBandC = 0, stampDutyBandD = 0, stampDutyBandE = 0;
    let taxSumBandA = 0, taxSumBandB = 0, taxSumBandC = 0, taxSumBandD = 0, taxSumBandE = 0;

    let bandA = $('div#additional tr.band-a');
    let bandB = $('div#additional tr.band-b');
    let bandC = $('div#additional tr.band-c');
    let bandD = $('div#additional tr.band-d');

    if (price < 40000) {
        stampDuty = 0;
    } else if (price >= 40000 && price <= 500000.00) {
        stampDuty = price * 0.03;
    } else if (price >= 500000.01 && price <= 925000.00) {
        stampDuty = stampDuty = ((price - 500000) * 0.08) + 15000;
    } else if (price >= 925000.01 && price <= 1500000.00) {
        stampDuty = ((price - 925000) * 0.13) + 49000;
    } else if (price >= 1500000.01) {
        stampDuty = ((price - 1500000) * 0.15) + 123750;
    }

    effectiveRateLong = (stampDuty / price) * 100;
    effectiveRate = Math.round(effectiveRateLong * 100) / 100.000;

    if (price < 40000) {
        stampDutyBandA = Math.round(0);
    } else if (price >= 40000 && price <= 500000.00) {
        stampDutyBandA = Math.round((price) * 0.03);
    } else if (price >= 500000.01 && price <= 925000.00) {
        stampDutyBandA = Math.round(15000);
        stampDutyBandB = Math.round(((price - 500000) * 0.08));
    } else if (price >= 925000.01 && price <= 1500000.00) {
        stampDutyBandA = Math.round(15000);
        stampDutyBandB = Math.round(34000);
        stampDutyBandC = Math.round(((price - 925000) * 0.13));
    } else if (price >= 1500000.01) {
        stampDutyBandA = Math.round(15000);
        stampDutyBandB = Math.round(34000);
        stampDutyBandC = Math.round(74750);
        stampDutyBandD = Math.round(((price - 1500000) * 0.15));
    }

    if (price < 40000) {
        taxSumBandA = Math.round(0);
    } else if (price >= 40000 && price <= 500000.00) {
        taxSumBandA = Math.round(price);
    } else if (price >= 500000.01 && price <= 925000.00) {
        taxSumBandA = Math.round(500000);
        taxSumBandB = Math.round((price - 500000));
    } else if (price >= 925000.01 && price <= 1500000.00) {
        taxSumBandA = Math.round(500000);
        taxSumBandB = Math.round(425000);
        taxSumBandC = Math.round((price - 925000));
    } else if (price >= 1500000.01) {
        taxSumBandA = Math.round(500000);
        taxSumBandB = Math.round(425000);
        taxSumBandC = Math.round(575000);
        taxSumBandD = Math.round((price - 1500000));
    }

    $('td.taxable-sum', bandA).text('£' + taxSumBandA.toFixed(2).replace('.00', ''));
    $('td.tax', bandA).text('£' + stampDutyBandA.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandB).text('£' + taxSumBandB.toFixed(2).replace('.00', ''));
    $('td.tax', bandB).text('£' + stampDutyBandB.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandC).text('£' + taxSumBandC.toFixed(2).replace('.00', ''));
    $('td.tax', bandC).text('£' + stampDutyBandC.toFixed(2).replace('.00', ''));

    $('td.taxable-sum', bandD).text('£' + taxSumBandD.toFixed(2).replace('.00', ''));
    $('td.tax', bandD).text('£' + stampDutyBandD.toFixed(2).replace('.00', ''));

    $('div#additional td.effective-rate').text(effectiveRate.toFixed(2).replace('.00', '') + '%');
    $('div.stamp-duty-total span.estimated-result').text('£' + stampDuty.toFixed(2).replace('.00', ''));
    $('div.stamp-duty-total').removeClass('d-none');
}
